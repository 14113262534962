import { FETCHING_HAZARDS, FETCHING_HAZARDS_SUCCESS, FETCH_HAZARDS_ERROR } from 'constants/index';

export const INITIAL_STATE = { data: [], error: null, isFetching: false };

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCHING_HAZARDS_SUCCESS:
      return {
        data: action.payload.hazards,
        isFetching: false,
        error: false
      };
    case FETCH_HAZARDS_ERROR:
      return {
        ...state,
        error: action.payload.error,
        isFetching: false
      };
    case FETCHING_HAZARDS:
      return {
        ...state,
        isFetching: true
      };
    default:
      return state;
  }
}
