import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import reducers from "./reducers/index";
import logger from 'redux-logger';
// import Reducers from './reducers';

export const ConfigureStore = () => {
  const store = createStore(reducers, composeWithDevTools(applyMiddleware(thunkMiddleware, logger),
  ));

  return store;
};

export default ConfigureStore;



// const store = createStore(
//   reducers,
//   {},
//   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// );

// export default store;