import { Box, Flex, Image, VStack } from '@chakra-ui/react';
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Account from 'components/Account';
import axios from "axios";
import { generateToken } from '../service/api'
import { setUsers } from "../redux/actions/Actions";
import { useParams } from 'react-router-dom';

const jwt = require('jsonwebtoken');
export default function Home() {

  const users = useSelector((state) => state.allUsers.users);
  const dispatch = useDispatch();
  const { token } = useParams();
  const monday_auth_url = `${process.env.REACT_APP_MONDAY_AUTH_URL}?client_id=${process.env.REACT_APP_MONDAY_CLIENT}&state=${token}`;
  const { accountId, userId, backToUrl, appKind, entityId } = jwt.verify(token, process.env.REACT_APP_MONDAY_SECRET);
  const backToUrlSplit = backToUrl.split('/')
  const automation_id = backToUrlSplit[backToUrlSplit.length - 1]
  useEffect(() => {
    const fetchUsers = async (accountid, userid) => {
      const token = await generateToken({
        accountId,
        userId
      })
      const response = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/accounts`,
        headers: {
          Authorization: token
        }
      })
      .catch((err) => {
          console.log("Err: ", err);
        });
        dispatch(setUsers(response.data.data));
    };
   
    fetchUsers(accountId,userId);
  }, []);

  const storeWebhook = async (account_id, monday_user_id, pb_user_id) => {
    const token = await generateToken({
      account_id,
      monday_user_id,
      pb_user_id,
      automation_id
    })
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/webhooks/placeholder`,
      headers: {
        Authorization: token
      }
    })
    .then(() => { })
    .catch((err) => {
      console.log("Err: ", err);
    });

  }

  const storeView = async (account_id, pb_user_id, view_id) => {
    const token = await generateToken({
      account_id,
      pb_user_id,
      view_id
    })
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/views`,
      headers: {
        Authorization: token
      }
    })
    .catch((err) => {
      console.log("Err: ", err);
    });

  }
  
  const checkApp = async (account_id, pb_user_id, entity_id) => {
    console.log(userId)
    if (appKind === '_board-view_') {
      storeView(account_id, pb_user_id, entity_id);
    } else if (appKind === '_item-view_') {
      storeView(account_id, pb_user_id, entity_id);
    } else {
      storeWebhook(account_id, userId, pb_user_id);
    }
  }

  return (
    <Box w="100%">
      <Flex justifyContent="center" alignItems="center">
      <Image
          borderRadius="full"
          htmlWidth="300px"
          htmlHeight="100px"
          src="./assets/images/cta-overall.png"
          alt="Segun Adebayo"
        />
      </Flex>
      <Flex py={6} fontSize="2xl" justifyContent="center">
        Choose an account
      </Flex>
      <VStack align="center">
        

{users.map(users => {
    const { pb_email, pb_user_id } = users;
    return (<>
    <a href={backToUrl} onClick={(() => { checkApp(accountId, pb_user_id, entityId) })}>
      <Account key={pb_email} accountid = {accountId} userid = {userId} email={pb_email}/>
    </a>
    </>
  )
})};


<a href={`${monday_auth_url}`} rel="noopener noreferrer">
          <Flex
            boxShadow="base"
            borderStyle="dotted"
            borderWidth="2px"
            borderRadius="5px"
            alignItems="center"
            minW="20rem"
            borderColor="gray.500"
            justifyContent="center"
            cursor="pointer"
            p={2}
          >
           
   Add account
        
          </Flex></a>
          
      </VStack>
    </Box>
  );
}