import { DeleteIcon, LinkIcon } from '@chakra-ui/icons';

import { Flex } from '@chakra-ui/react';
import React from 'react';
import { deleteAccount as deleteAccountAPI } from "../../service/api"

export default function Account({ accountid, userid, email }) {

  const deleteAccount = async () => {
    await deleteAccountAPI(accountid, userid, email)
    refreshPage();
  }

  function refreshPage() {
    window.location.reload();
  }
  
  return (
    <>
      <Flex
      >
          <Flex
            borderWidth="1px"
            borderRadius="5px"
            boxShadow="lg"
            bg="gray.100"
            alignItems="center"
            minW="18rem"
            mb={3}
            p={2}
            cursor="pointer"
            pr={5}
          >
            <LinkIcon mr={30} />
            {email}
          </Flex>
        &nbsp;
        <button onClick={deleteAccount}><DeleteIcon /></button>
      </Flex>
    </>
  );
}
