import { Flex, HStack, Image, Input, VStack, Text, Button } from '@chakra-ui/react';
import React from 'react';

export default function Connect() {
  return (
    <HStack minH="50%" w="100%">
      <Flex h="100%" justifyContent="center" alignItems="center" w="50%">
        <Image
          borderRadius="full"
          htmlWidth="300px"
          htmlHeight="250px"
          src="./assets/images/cta-overall.png"
          alt="Segun Adebayo"
        />
      </Flex>
      <Flex h="100%" w="50%">
        <VStack align="flex-start" justifyContent="center">
          <h1>Connect to PhoneBurner</h1>
          <Text fontSize="sm">Please enter your PhoneBurner Database Login URL</Text>
          <Input placeholder="" />
          <Button colorScheme="blue">Connect</Button>
        </VStack>
      </Flex>
    </HStack>
  );
}
