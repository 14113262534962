import { Flex } from '@chakra-ui/react';
import React from 'react';

export default function AppLayout({ children }) {
  return (
    <Flex wrap="wrap" w="100%" minH="100vh">
      {children}
    </Flex>
  );
}
