import React from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { BrowserRouter as Router } from 'react-router-dom';
import Main from 'pages/Main';
import { Provider } from 'react-redux';
import { ConfigureStore } from './redux/store';
import AppLayout from 'components/AppLayout';
import { Fonts } from 'components/Font';
require('dotenv').config()
const store = ConfigureStore();

const theme = extendTheme({
  fonts: {
    heading: 'Raleway',
    body: 'Raleway'
  },
  colors: {
    red300: { 500: '#FC8181' },
    red200: { 500: '#FEB2B2' },
    red: {
      300: '#FC8181',
      400: '#F56565',
      500: '#E53E3E'
    },
    error: { 500: '#FF3E55' }
  }
});

function App() {
  return (
    <Router>
      <Provider store={store}>
        <ChakraProvider theme={theme}>
          <Fonts />
          <AppLayout>
            <Main />
          </AppLayout>
        </ChakraProvider>
      </Provider>
    </Router>
  );
}

export default App;
