import { FETCHING_INFORMATION, FETCHING_INFORMATIONS_SUCCESS, FETCH_INFORMATION_ERROR } from 'constants/index';

export const INITIAL_STATE = { data: [], error: null, isFetching: false };

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCHING_INFORMATIONS_SUCCESS:
      return {
        data: action.payload.info,
        isFetching: false,
        error: false
      };
    case FETCH_INFORMATION_ERROR:
      return {
        ...state,
        error: action.payload.error,
        isFetching: false
      };
    case FETCHING_INFORMATION:
      return {
        ...state,
        isFetching: true
      };
    default:
      return state;
  }
}
