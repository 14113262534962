import { Flex, HStack } from '@chakra-ui/react';
import React from 'react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';

export default function Menu() {
  const history = useHistory();

  return (
    <HStack w="100%">
      <Flex cursor="pointer" onClick={() => history.goBack()} alignItems="center" p={3}>
        <ChevronLeftIcon />
        Back
      </Flex>
    </HStack>
  );
}