import axios from "axios";
import jwt from "jsonwebtoken";

let api = null;

export async function generateToken(data) {
  const backToUrl = `https://monday.com/`
  const token = jwt.sign(
    { 
      backToUrl,
      ...data
    },
    process.env.REACT_APP_MONDAY_SECRET
  );
  return token
}

export async function createApi(data = null) {
  const token = await generateToken(data)
  if (!api) {
    api = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    });

    api.interceptors.request.use(async (conf) => {
      const config = conf;
      config.headers.Authorization = token;
      config.headers["Content-Type"] = "application/json";

      return config;
    });
    return api;
  } else {
    api.defaults.Authorization = token;
    return api;
  }
}

export async function deleteAccount(accountId, userId, pbemail) {
  const carbonApi = await createApi({ accountId, userId, pbemail });
  const response = await carbonApi.delete("accounts").then().catch();
  return response.data;
}

export default api;
