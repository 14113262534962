import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

export const PublicRoute = ({ component: Component, path, auth, ...rest }) => {
  const render = props => <Component {...props} />;

  return <Route path={path} render={render} {...rest} />;
};

PublicRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired
};
