import { Flex } from '@chakra-ui/react';
import React from 'react';

export default function Footer() {
  return (
    <Flex py={4} px={3}>
      PhoneBurner
    </Flex>
  );
}
