export const FETCHING_INFORMATION = 'FETCHING_INFORMATION';
export const FETCH_INFORMATION_ERROR = 'FETCH_INFORMATION_ERROR';
export const FETCHING_INFORMATIONS_SUCCESS = 'FETCHING_INFORMATIONS_SUCCESS';

export const FETCHING_HAZARDS = 'FETCHING_HAZARDS';
export const FETCH_HAZARDS_ERROR = 'FETCH_HAZARDS_ERROR';
export const FETCHING_HAZARDS_SUCCESS = 'FETCHING_HAZARDS_SUCCESS';

export const FETCHING_CONTACTS = 'FETCHING_CONTACTS';
export const FETCH_CONTACTS_ERROR = 'FETCH_CONTACTS_ERROR';
export const FETCHING_CONTACTS_SUCCESS = 'FETCHING_CONTACTS_SUCCESS';

export const FETCHING_FORMS = 'FETCHING_FORMS';
export const FETCH_FORMS_ERROR = 'FETCH_FORMS_ERROR';
export const FETCHING_FORMS_SUCCESS = 'FETCHING_FORMS_SUCCESS';
