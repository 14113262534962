import { combineReducers } from 'redux';
import Info from './information';
import Hazards from './hazards';
import Contacts from './contacts';
import Forms from './forms';
import { usersReducer} from "./UserReducer";

const reducers = combineReducers({ Info, Hazards, Contacts, Forms,
    allUsers: usersReducer,
 });

export const INITIAL_STATE = {};
export default reducers;

