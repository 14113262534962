import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import Home from './Home';
import { publicsRoutes } from 'routes';
import Menu from 'components/Menu';
import { PublicRoute } from 'components/Route';
import Footer from 'components/Footer';
import Layout from 'components/Layout';
import Connect from './Connect';

export default function Main() {
  const pages = { home: Home, connect: Connect };

  return (
    <>
      <Menu />
      <Switch>
        <Layout>
          {Object.keys(publicsRoutes).map(key => (
            <PublicRoute
              key={key}
              auth={publicsRoutes[key].auth}
              path={publicsRoutes[key].path}
              exact={publicsRoutes[key].exact}
              component={pages[publicsRoutes[key].component]}
            />
          ))}
        </Layout>
        <Redirect to="/:token" />
      </Switch>
      <Footer />
    </>
  );
}
