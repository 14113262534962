import { Box, Container } from '@chakra-ui/react';
import React from 'react';

export default function Layout({ children }) {
  return (
    <Box w="100%" minH="90vh" textAlign="center" fontSize="xl">
      <Container justifyContent="center" h="100%" centerContent>
        {children}
      </Container>
    </Box>
  );
}
